<template>
  <v-footer
    class="py-4 custom-bg"
    height="auto"
  >
    <v-container
      class="pa-0"
      fluid
      fill-height>
      <v-container>
        <v-row row wrap>
          <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" sm="1" md="1" lg="2" class="pl-3 pt-5">
          </v-col>
          <v-col cols="12" xs="6" sm="6" md="4" lg="4" class="pl-3 pr-1 pt-5 pb-2 mb-2">
            <v-row row wrap>
              <v-col cols="6">
                <v-row row wrap>
                  <v-col cols="12" class="pa-1 ma-1"><span class="body-1"><strong>{{ $t('MeeTeeMeeNgern1.aboutUsHeader') }}</strong></span></v-col>
                  <!-- <v-col cols="12" class="pa-0 ma-1"><router-link :class="[$vuetify.breakpoint.lgAndUp ? 'body-1' : 'capture']" to="/About">{{ $t('MeeTeeMeeNgern1.History') }}</router-link></v-col> -->
                  <v-col cols="12" class="pa-0 ma-1"><router-link :class="[$vuetify.breakpoint.lgAndUp ? 'body-1' : 'capture']" to="/Goal">{{ $t('MeeTeeMeeNgern1.GoalHeader') }}</router-link></v-col> 
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row row wrap>
                  <v-col cols="12" class="pa-1 ma-1"><span class="body-1"><strong>{{ $t('MeeTeeMeeNgern1.product') }}</strong></span></v-col>
                  <v-col cols="12" class="pa-0 ma-1"><router-link :class="[$vuetify.breakpoint.lgAndUp ? 'body-1' : 'capture']" :to="{ name: 'Product', params: { propValue: 1 }}">สินเชื่อ มีที่ มีเงิน</router-link></v-col>
                  <!-- <v-col cols="12" class="pa-0 ma-1"><router-link :class="[$vuetify.breakpoint.lgAndUp ? 'body-1' : 'capture']" :to="{ name: 'Product', params: { propValue: 2 }}">ขายฝาก</router-link></v-col> -->
                  <v-col v-show="RegisterShow" cols="12" class="pa-0 ma-1"><router-link :class="[$vuetify.breakpoint.lgAndUp ? 'body-1' : 'capture']" to="/register">สมัครสินเชื่อ</router-link></v-col>
                  <v-col cols="12" class="pa-0 ma-1"><router-link :class="[$vuetify.breakpoint.lgAndUp ? 'body-1' : 'capture']" to="/DocumentDownload">ดาวน์โหลดเอกสาร</router-link></v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col cols="12" md="7" lg="5" class="pl-1 pr-1 pt-5 pb-0"> -->
          <v-col cols="12" xs="7" sm="7" md="5" lg="5" class="pl-1 pr-1 pt-5 pb-0">
            <v-row row wrap>
              <v-col cols="6">
                <v-row row wrap>
                  <v-col cols="12" class="pa-1 ma-1"><span class="body-1"><strong>{{ $t('MeeTeeMeeNgern1.branch') }}</strong></span></v-col>
                  <v-col cols="12" class="pa-0 ma-1"><router-link :class="[$vuetify.breakpoint.lgAndUp ? 'body-1' : 'capture']" to="/BranchGSB">{{ $t('MeeTeeMeeNgern1.BranchGSBHeader') }}</router-link></v-col>
                  <!-- <v-col cols="12" class="pa-0 ma-1"><router-link :class="[$vuetify.breakpoint.lgAndUp ? 'body-1' : 'capture']" to="/BranchTIP">{{ $t('MeeTeeMeeNgern1.BranchTIPHeader') }}</router-link></v-col>
                  <v-col cols="12" class="pa-0 ma-1"><router-link :class="[$vuetify.breakpoint.lgAndUp ? 'body-1' : 'capture']" to="/BranchBCP">{{ $t('MeeTeeMeeNgern1.BranchBCPHeader') }}</router-link></v-col> -->
                </v-row>
              </v-col>
              <v-col cols="6" class="pl-0">
                <v-row row wrap>
                  <v-col cols="12" class="pa-1 ma-1"><span class="body-1"><strong>{{ $t('MeeTeeMeeNgern1.Etc') }}</strong></span></v-col>
                  <v-col cols="12" class="pa-0 ma-1"><router-link :class="[$vuetify.breakpoint.lgAndUp ? 'body-1' : 'capture']" to="/Articles">{{ $t('MeeTeeMeeNgern1.Articles') }}</router-link></v-col>
                  <v-col cols="12" class="pa-0 ma-1"><router-link :class="[$vuetify.breakpoint.lgAndUp ? 'body-1' : 'capture']" to="/Subscribe">{{ $t('MeeTeeMeeNgern1.Subscribe') }}</router-link></v-col>
                  <v-col cols="12" class="pa-0 ma-1"><router-link :class="[$vuetify.breakpoint.lgAndUp ? 'body-1' : 'capture']" to="/workWithUs">{{ $t('MeeTeeMeeNgern1.workWithUs') }}</router-link></v-col>
                  <!-- <v-col cols="12" class="pa-0 ma-1"><router-link :class="[$vuetify.breakpoint.lgAndUp ? 'body-1' : 'capture']" to="/Promotions">{{ $t('MeeTeeMeeNgern1.promotions') }}</router-link></v-col> -->
                  <v-col cols="12" class="pa-0 ma-1"><router-link :class="[$vuetify.breakpoint.lgAndUp ? 'body-1' : 'capture']" to="/cookie-policy">{{ $t('MeeTeeMeeNgern1.messagePolicy') }}</router-link></v-col>
                  <v-col cols="12" class="pa-0 ma-1"><router-link :class="[$vuetify.breakpoint.lgAndUp ? 'body-1' : 'capture']" to="/cookie-policy-private">{{ $t('MeeTeeMeeNgern1.CookiePolicyPrivateHeader') }}</router-link></v-col>
                  <v-col cols="12" class="pa-0 ma-1"><router-link :class="[$vuetify.breakpoint.lgAndUp ? 'body-1' : 'capture']" to="/login">{{ $t('MeeTeeMeeNgern1.employee') }}</router-link></v-col>
                </v-row>
                <!-- <router-link :to="{path: '/Homer'}" exact tag="li"><a>Homer</a></router-link> -->
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="12" md="1" class="pt-5">
          </v-col>
          <!-- <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="12" align="center" md="3" lg="3" class="pl-1 pr-1 pt-5 pb-0">
            <v-img
              :src="require('@/assets/MeeTeeMeeNgern/logo.jpg')"
              class="mr-5"
              contain
              height="150"
              width="150"
              max-width="150"
              @click="$router.push('/')"
            />
          </v-col> -->
        </v-row>
      </v-container>
      <cookie-law ref="cookieBanner">
        <div slot-scope="props">
          <v-btn text color="error" dark small fab @click="closeCookie">
            <v-icon>close</v-icon>
          </v-btn>
          <span class="text--primary body-1">
            {{ $t('MeeTeeMeeNgern1.messageCookieLaw') }}
          </span>
          <router-link class="body-1 text-decoration-underline" to="/cookie-policy-private">{{ $t('MeeTeeMeeNgern1.messageCookieLaw2') }}</router-link>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <v-row align="center" class="pt-0">
            <v-col cols="12" sm="4" md="2" class="pa-0">
              <v-switch v-model="necessaryCookies" class="caption" hide-details>
                <template v-slot:label>
                  <div><span class="text--primary body-2">คุกกี้ที่จำเป็น</span></div>
                </template>
              </v-switch>
            </v-col>
            <v-col cols="12" sm="4" md="2" class="pa-0">
              <v-switch v-model="analyticCookies" hide-details>
                <template v-slot:label>
                  <div><span class="text--primary body-2">คุกกี้เพื่อการวิเคราะห์</span></div>
                </template>
              </v-switch>
            </v-col>
            <v-col cols="12" sm="4" md="2" class="pa-0">
              <v-switch v-model="functionalCookies" hide-details>
                <template v-slot:label>
                  <div><span class="text--primary body-2">คุกกี้เพื่อช่วยในการใช้งาน</span></div>
                </template>
              </v-switch>
            </v-col>
            <v-col cols="12" sm="4" md="3" class="pa-0">
              <v-switch v-model="targetingCookies" hide-details>
                <template v-slot:label>
                  <div><span class="text--primary body-2">คุกกี้เพื่อปรับเนื้อหาให้เข้ากับกลุ่มเป้าหมาย</span></div>
                </template>
              </v-switch>
            </v-col>
            <v-btn @click="declineCookie" outlined color="error" class="body-1" small>ปฎิเสธ</v-btn>
            <v-divider class="mx-1" inset vertical></v-divider>
            <v-btn @click="acceptCookie(false)" outlined color="success" class="body-1" small><span>บันทีกความยินยอม</span></v-btn>
            <v-divider class="mx-1" inset vertical></v-divider>
            <v-btn @click="acceptCookie(true)" outlined color="success" class="body-1" small><span>ยอมรับทั้งหมด</span></v-btn>
          </v-row>
        </div>
      </cookie-law>
      <v-dialog
        v-model="dialog.dialogPolicy"
        width="800px"
      >
        <v-card>
          <!-- <v-card-title>
            <span class="text-h5">Use Google's location service?</span>
          </v-card-title> -->
          <v-card-text>
            <cookiepolicyprivate></cookiepolicyprivate>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="pink"
              dark
              text
              @click="dialog.dialogPolicy = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-footer>
</template>

<script>
  import CookieLaw from 'vue-cookie-law'
  export default {
    name: 'CoreFooter',
    components: {
      CookieLaw,
      cookiepolicyprivate: () => import('@/components/cookie-policy-private'),
    },
    /* computed: {
      buttonText: this.$t('MeeTeeMeeNgern1.Accept')
    }, */
    computed: {
      buttonText () {
        /* return this.editedIndex === -1 ? this.$t('navWindow.newWindow') : this.$t('navWindow.editWindow') */
        return this.$t('MeeTeeMeeNgern1.Accept')
      },
      areCookiesAccepted () {
        return this.$refs.cookieBanner.isAccepted()
      },
    },
    created: function () {
      this.getGlobalRegisterShow()
    },
    data: () => ({
      items: [
        {
          href: '#!',
          icon: 'mdi-twitter',
        },
        /* {
          href: '#!',
          icon: 'mdi-line',
        }, */
        {
          href: '#!',
          icon: 'mdi-facebook',
        },
      ],
      dialog: {
        dialogPolicy: false,
      },
      necessaryCookies: true,
      analyticCookies: false,
      functionalCookies: false,
      targetingCookies: false,
      RegisterShow: false,
    }),
    methods: {
      CookieLaw () {
        this.dialog.dialogPolicy = true
      },
      ProductPage (value) {
        console.log(value, 'valuevaluevaluevalue')
        if (this.$route.path !== '/Product') {
          this.$router.push({ name: 'Product', params: { propValue: value } })
        } else {
          this.$router.push('/About')
          this.$router.push({ name: 'Product', params: { propValue: value } })
        }
      },
      closeCookie () {
        this.$refs.cookieBanner.isOpen = false
      },
      declineCookie () {
        localStorage.setItem('cookie:accepted', false)
        localStorage.removeItem('cookies')
        this.$refs.cookieBanner.isOpen = false
      },
      acceptCookie (allCookies) {
        if (allCookies) {
          this.analyticCookies = true
          this.functionalCookies = true
          this.targetingCookies = true
        }
        var cookies = {
          'Necessary Cookies': this.necessaryCookies,
          'Analytic Cookies': this.analyticCookies,
          'Functional Cookies': this.functionalCookies,
          'Targeting Cookies': this.targetingCookies
        }
        localStorage.setItem('cookie:accepted', true)
        localStorage.setItem('cookies', JSON.stringify(cookies))
        this.$refs.cookieBanner.isOpen = false
      },
      getGlobalRegisterShow () {
        this.axios.post(process.env.VUE_APP_API + '/user/getGlobalRegisterShow', {
        })
          .then(response => {
            if (response.data.isSuccess) {
              if (response.data.data === 'Y' || response.data.data === 'y') {
                this.RegisterShow = true
              } else {
                this.RegisterShow = false
              }
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
    }
  }
</script>
<style scoped>
  a {
    color:rgb(71, 71, 71);
    text-decoration: none;
    font-size:large;
  }
  /* .router-link-exact-active {
    background: yellow;
    border-radius: 5px;
    color: red;
    font-variant: italic;
  } */
  .custom-bg {
    background: rgb(189, 193, 187) !important;
    /* background: url('../../assets/bg/bg1.jpg');
    background-size: cover; */
  }
  .link {
    margin: 10px;
  }
  .link.active {
    background-color: red;
    color: white;
  }
</style>